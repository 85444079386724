import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import Swatches from '../components/shortcodes/Swatches'
import ContactForm from '../components/shortcodes/ContactForm'
import ProductPageStyles from '../styles/ProductPage.module.sass'

/**
 * Display a dynamic product page template
 */
const Product = ({data}) => {
  // Define class names to separate logic from JSX structure
  const pageClassNames = `is-page-${data.mdx.fields.pageIdentifier} single-product`
  const topSplitClassNames = ProductPageStyles.topSplit || ''
  const imageHolderClassNames = ProductPageStyles.imageHolder || ''
  const imageClassNames = ProductPageStyles.image || ''
  const descriptionClassNames = ProductPageStyles.description || ''
  const productTitleClassNames = ProductPageStyles.productTitle || ''
  const pricesClassNames = ProductPageStyles.prices || ''
  const contentsClassNames = ProductPageStyles.contents || ''
  const contentsListClassNames = ProductPageStyles.contentsList || ''
  const partsImageClassNames = ProductPageStyles.partsImage || ''
  const cubicleLayoutsClassNames = ProductPageStyles.cubicleLayouts || ''
  const contactFormClassNames = ProductPageStyles.contactForm || ''

  return (
    <Layout
      className={pageClassNames}
      seoTitle={''}
      seoDescription={''}
    >
      <div className={topSplitClassNames}>
        <div className={'container'}>
          <div className={'columns'}>
            <div className={'column is-half-tablet is-three-fifths-desktop'}>
              <div className={imageHolderClassNames}>
                <Img
                  className={imageClassNames}
                  fluid={data.mdx.fields.image.childImageSharp.fluid}
                />
                <i>
                  <img src='/media/logo-white.svg' alt='Toilet Cubicles Ireland' />
                </i>
              </div>
              
              <div className={`notification is-primary is-light has-text-centered ${descriptionClassNames}`}>
                {data.mdx.frontmatter.fullDescription}
              </div>
              <Swatches />
            </div>
            <div className={'column is-half-tablet is-two-fifths-desktop'}>
              <h1 className={`title is-2 ${productTitleClassNames}`}>
                {data.mdx.frontmatter.title}
              </h1>
              <ul className={pricesClassNames || ''}>
                <li>Supply Only from <b>&euro;{data.mdx.frontmatter.priceSupply}</b></li>
                <li>Standard Installation from <b>&euro;{data.mdx.frontmatter.priceInstallation}</b></li>
              </ul>
              <ContactForm 
                className={contactFormClassNames} 
                numberOfCubicles={data.mdx.frontmatter.count} 
                supplySelector={true} 
                wallSelector={true} 
              />
            </div>
          </div>
        </div>
      </div>
      <div className={contentsClassNames}>
        <div className={'container'}>
          <div className={'columns'}>
            <div className={'column is-half-tablet is-two-fifths-desktop'}>
              <h2 className={'title is-2'}>What's Included</h2>
              <ul className={contentsListClassNames}>
                {data.mdx.frontmatter.fullContents.map((item, index) => {
                  return (
                    <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                  )
                })}
              </ul>
            </div>
            <div className={`column is-half-tablet is-three-fifths-desktop ${partsImageClassNames}`}>
              <div>
                <img
                  src="/media/illustrations/toilet-cubicle-parts.png"
                  alt="Toilet Cubicle Parts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cubicleLayoutsClassNames}>
        <div className={'container'}>
          <h2 className={'title is-2'}>Toilet Cubicle Layouts</h2>
          <div className={'columns'}>
            <div className={'column is-one-third'}>
              <img
                src="/media/illustrations/toilet-cubicles-between-walls.png"
                alt="toilet cubicles between walls"
              />
              <strong className='title is-4'>Between Walls</strong>
              <p>If your cubicles are positioned between two walls, the first and last cubicles do not need any end panels.</p>
            </div>
            <div className={'column is-one-third'}>
              <img
                src="/media/illustrations/toilet-cubicles-with-wall-on-one-side.png"
                alt="toilet cubicles with wall on one side"
              />
              <strong className='title is-4'>Wall on One Side</strong>
              <p>If your row of cubicles ends with a wall on one side, the cubicle beside the wall does not need an end panel.</p>
            </div>
            <div className={'column is-one-third'}>
              <img
                src="/media/illustrations/toilet-cubicles-island.png"
                alt="toilet cubicles away from walls (island)"
              />
              <strong className='title is-4'>Away from Walls (Island)</strong>
              <p>If your cubicles don't have a wall on any side, the first and last cubicles will need freestanding end panels.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Product

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        seoTitle
        seoDescription
        count
        priceSupply
        priceInstallation
        fullDescription
        fullContents
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
